/*
 * @Author: your name
 * @Date: 2020-10-14 15:12:40
 * @LastEditTime: 2020-10-15 14:27:19
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /bms-seller/src/api/operate.js
 */
import request from '@/utils/request'

/**
 * 运营
 */

/**
 * 浏览数据
 */
export function getBrowseData(params) {
  return request({
    url: 'seller/statistics/shop/tradedata',
    method: 'get',
    params
  })
}

/**
 * 优惠券使用情况
 */
export function getCounpon(params) {
  return request({
    url: 'seller/statistics/shop/couponlist',
    method: 'get',
    params
  })
}

/**
 * 交易数据
 */
export function getTrade(params) {
  return request({
    url: 'seller/statistics/shop/tradedatatop',
    method: 'get',
    params
  })
}
