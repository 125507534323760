<template>
  <div class="page-ops-report">
    <!-- 交易数据 -->
    <el-tabs type="card">
      <div class="echartsTitle">
        <p>交易数据</p>
        <div>
          <el-button :type="tradeDay === 3?'danger':'primary'" size="mini" @click="tradeDayChange(3)">昨日</el-button>
          <el-button :type="tradeDay === 4?'danger':'primary'" size="mini" @click="tradeDayChange(4)">今日</el-button>
          <el-button :type="tradeDay === 0?'danger':'primary'" size="mini" @click="tradeDayChange(0)">最近7天</el-button>
          <el-button :type="tradeDay === 1?'danger':'primary'" size="mini" @click="tradeDayChange(1)">最近1个月</el-button>
          <el-button :type="tradeDay === 2?'danger':'primary'" size="mini" @click="tradeDayChange(2)">最近一年</el-button>
          <el-date-picker style="width: 280px; margin-left: 10px;" v-model="tradeTimerange" type="daterange"
                          value-format="timestamp"
                          range-separator="~" size="mini" start-placeholder="开始时间"
                          end-placeholder="结束时间"></el-date-picker>
        </div>
      </div>
      <div class="content">
        <div class="left">
          <div class="one">
            <div v-for="(item, index) in tradeLeftDataOne" :key="index" class="tradeDiv">
              <span class="tradeValue">{{ item.value ? item.value : 0 }}</span>
              <p class="tradeName">{{ item.name }}</p>
            </div>
          </div>
          <div class="two">
            <div v-for="(item, index) in tradeLeftDataTwo" :key="index" class="tradeDiv">
              <span class="tradeValue">{{ item.value ? item.value : 0 }}</span>
              <p class="tradeName">{{ item.name }}</p>
            </div>
          </div>
          <div class="three">
            <div v-for="(item, index) in tradeLeftDataThree" :key="index" class="tradeDivThree">
              <span class="tradeValue">¥{{ item.value ? item.value : 0 }}</span>
              <p class="tradeName">{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div id="funel" style="height: 248px; width: 286px;"></div>
      </div>
    </el-tabs>
    <!-- 活动详情页浏览排行 -->
    <el-tabs>
      <div class="echartsTitle">
        <p>活动详情页浏览排行</p>
        <div>
          <el-button :type="browseDay === 3?'danger':'primary'" size="mini" @click="browseDayChange(3)">昨日</el-button>
          <el-button :type="browseDay === 4?'danger':'primary'" size="mini" @click="browseDayChange(4)">今日</el-button>
          <el-button :type="browseDay === 0?'danger':'primary'" size="mini" @click="browseDayChange(0)">最近7天</el-button>
          <el-button :type="browseDay === 1?'danger':'primary'" size="mini" @click="browseDayChange(1)">最近1个月
          </el-button>
          <el-button :type="browseDay === 2?'danger':'primary'" size="mini" @click="browseDayChange(2)">最近一年</el-button>
          <el-date-picker style="width: 280px; margin-left: 10px;" v-model="browsetTimerange" type="daterange"
                          value-format="timestamp"
                          range-separator="~" size="mini" start-placeholder="开始时间"
                          end-placeholder="结束时间"></el-date-picker>
        </div>
      </div>
      <div class="content1">
        <el-table
          :data="browseTableData.data"
          stripe
          style="width: 100%">
          <el-table-column prop="goods_name" label="活动名称"/>
          <el-table-column prop="pv_num" label="浏览量（PV）"/>
          <el-table-column prop="uv_num" label="浏览人数（UV）"/>
          <el-table-column prop="view_minute" label="人均浏览时长/min"/>
          <el-table-column prop="order_num" label="订单数量"/>
          <el-table-column prop="rate" label="单品转化率"/>
          <el-table-column prop="pay_price_num" label="销售金额"/>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handlePageSizeChange"
            @current-change="handlePageCurrentChange"
            :current-page="browseTableData.page_no"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="browseTableData.page_size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="browseTableData.data_total">
          </el-pagination>
        </div>
      </div>
    </el-tabs>
    <!-- 优惠券使用情况 -->
    <el-tabs>
      <div class="echartsTitle">
        <p>优惠券使用情况</p>
        <div>
          <el-button :type="couponDay === 3?'danger':'primary'" size="mini" @click="couponDayChange(3)">昨日</el-button>
          <el-button :type="couponDay === 4?'danger':'primary'" size="mini" @click="couponDayChange(4)">今日</el-button>
          <el-button :type="couponDay === 0?'danger':'primary'" size="mini" @click="couponDayChange(0)">最近7天</el-button>
          <el-button :type="couponDay === 1?'danger':'primary'" size="mini" @click="couponDayChange(1)">最近1个月
          </el-button>
          <el-button :type="couponDay === 2?'danger':'primary'" size="mini" @click="couponDayChange(2)">最近一年</el-button>
          <el-date-picker style="width: 280px; margin-left: 10px;" v-model="couponTimerange" type="daterange"
                          value-format="timestamp"
                          range-separator="~" size="mini" start-placeholder="开始时间"
                          end-placeholder="结束时间"></el-date-picker>
        </div>
      </div>
      <div class="content1">
        <el-table
          :data="couponTableData.data"
          stripe
          style="width: 100%">
          <el-table-column prop="title" label="优惠券名称"/>
          <el-table-column prop="used_num" label="已使用量"/>
          <el-table-column prop="create_num" label="总数"/>
          <el-table-column label="使用率">
            <template slot-scope="scope">
              {{scope.row.rate}}
            </template>
          </el-table-column>
          <el-table-column label="当前状态">
            <template slot-scope="scope">
              {{scope.row.coupon_status === 1 ? '进行中' : scope.row.coupon_status === 2 ? '未开始' : scope.row.coupon_status === 3 ? '已结束' : '已停用'}}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handlePageSizeChange1"
            @current-change="handlePageCurrentChange1"
            :current-page="couponTableData.page_no"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="couponTableData.page_size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="couponTableData.data_total">
          </el-pagination>
        </div>
      </div>
    </el-tabs>
  </div>
</template>

<script>
import * as API_Ope from '../../../api/operate'

export default {
  data() {
    return {
      // 交易参数
      tradeParams: {
        start: '',
        end: ''
      },
      tradeTimerange: '',
      tradeLeftDataOne: [{
        name: '店铺关注人数',
        value: ''
      }, {
        name: '店铺访问量（PV）',
        value: ''
      }, {
        name: '有效访问量（UV）',
        value: ''
      }],
      tradeLeftDataTwo: [{
        name: '有效订单量',
        value: ''
      }, {
        name: '退款订单数',
        value: ''
      }, {
        name: '总退款率',
        value: ''
      }],
      tradeLeftDataThree: [{
        name: '下单金额',
        value: ''
      }, {
        name: '平均客单量',
        value: ''
      }],
      order_avg: '',
      order_num: '',
      order_sum: '',
      pv_num: '',
      rate: '',
      re_num: '',
      shop_num: '',
      uv_num: '',
      tradeDay: 0,
      // 浏览参数
      browsetTimerange: '',
      browseDay: 0,
      browseParams: {
        page_no: 1,
        page_size: 10,
        start: '',
        end: ''
      },
      browseTableData: '',
      // 优惠券参数
      couponParams: {
        page_no: 1,
        page_size: 10,
        // start: '',
        // end: ''
      },
      couponTableData: '',
      couponTimerange: '',
      couponDay: 0
    }
  },
  watch: {
    tradeTimerange(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.tradeDay = 5
          this.tradeParams.start = newValue[0] / 1000
          this.tradeParams.end = newValue[1] / 1000
        } else {
          const today = new Date()
          const s = (today.getTime() - today.getHours() * 60 * 60 * 1000 - today.getMinutes() * 60 * 1000 - today.getSeconds() * 1000 - today.getMilliseconds()) / 1000
          this.tradeParams.start = s - (6 * 86400)
          this.tradeParams.end = s + 86399
        }
        this.tradeParams.page_no = 1
        this.getTradeData()
      }
    },
    browsetTimerange(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.browseDay = 5
          this.browseParams.start = newValue[0] / 1000
          this.browseParams.end = newValue[1] / 1000
        } else {
          const today = new Date()
          const s = (today.getTime() - today.getHours() * 60 * 60 * 1000 - today.getMinutes() * 60 * 1000 - today.getSeconds() * 1000 - today.getMilliseconds()) / 1000
          this.browseParams.start = s - (6 * 86400)
          this.browseParams.end = s + 86399
        }
        this.browseParams.page_no = 1
        this.getBrowseData()
      }
    },
    couponTimerange(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.couponDay = 5
          this.couponParams.start_time = newValue[0] / 1000
          this.couponParams.end_time = newValue[1] / 1000
        } else {
          const today = new Date()
          const s = (today.getTime() - today.getHours() * 60 * 60 * 1000 - today.getMinutes() * 60 * 1000 - today.getSeconds() * 1000 - today.getMilliseconds()) / 1000
          this.couponParams.start_time = s - (6 * 86400)
          this.couponParams.end_time = s + 86399
        }
        this.couponParams.page_no = 1
        this.getCouponData()
      }
    }
  },
  mounted() {
    this.echarts = this.$echarts.init(document.getElementById('funel'))
    this.getCouponData()
    this.getBrowseData()
    this.getTradeData()
    const today = new Date()
    const s = (today.getTime() - today.getHours() * 60 * 60 * 1000 - today.getMinutes() * 60 * 1000 - today.getSeconds() * 1000 - today.getMilliseconds()) / 1000
    this.tradeParams.start = s - (6 * 86400)
    this.tradeParams.end = s + 86399
    this.browseParams.start = s - (6 * 86400)
    this.browseParams.end = s + 86399
    this.couponParams.start_time = s - (6 * 86400)
    this.couponParams.end_time = s + 86399
  },
  methods: {
    // 按钮切换
    tradeDayChange(day) {
      this.tradeTimerange = ''
      this.tradeDay = day
      const today = new Date()
      const s = (today.getTime() - today.getHours() * 60 * 60 * 1000 - today.getMinutes() * 60 * 1000 - today.getSeconds() * 1000 - today.getMilliseconds()) / 1000
      if (day === 0) {
        this.tradeParams.start = s - (6 * 86400)
        this.tradeParams.end = s + 86399
      } else if (day === 1) {
        this.tradeParams.start = s - (29 * 86400)
        this.tradeParams.end = s + 86399
      } else if (day === 2) {
        this.tradeParams.start = s - (364 * 86400)
        this.tradeParams.end = s + 86399
      } else if (day === 3) {
        this.tradeParams.start = s - 86400
        this.tradeParams.end = s - 1
      } else {
        this.tradeParams.start = s
        this.tradeParams.end = s + 86399
      }
      this.getTradeData()
    },
    browseDayChange(day) {
      this.browsetTimerange = ''
      this.browseDay = day
      const today = new Date()
      const s = (today.getTime() - today.getHours() * 60 * 60 * 1000 - today.getMinutes() * 60 * 1000 - today.getSeconds() * 1000 - today.getMilliseconds()) / 1000
      if (day === 0) {
        this.browseParams.start = s - (6 * 86400)
        this.browseParams.end = s + 86399
      } else if (day === 1) {
        this.browseParams.start = s - (29 * 86400)
        this.browseParams.end = s + 86399
      } else if (day === 2) {
        this.browseParams.start = s - (364 * 86400)
        this.browseParams.end = s + 86399
      } else if (day === 3) {
        this.browseParams.start = s - 86400
        this.browseParams.end = s - 1
      } else {
        this.browseParams.start = s
        this.browseParams.end = s + 86399
      }
      this.browseParams.page_no = 1
      this.getBrowseData()
    },
    couponDayChange(day) {
      this.couponTimerange = ''
      this.couponDay = day
      const today = new Date()
      const s = (today.getTime() - today.getHours() * 60 * 60 * 1000 - today.getMinutes() * 60 * 1000 - today.getSeconds() * 1000 - today.getMilliseconds()) / 1000
      if (day === 0) {
        this.couponParams.start_time = s - (6 * 86400)
        this.couponParams.end_time = s + 86399
      } else if (day === 1) {
        this.couponParams.start_time = s - (29 * 86400)
        this.couponParams.end_time = s + 86399
      } else if (day === 2) {
        this.couponParams.start_time = s - (364 * 86400)
        this.couponParams.end_time = s + 86399
      } else if (day === 3) {
        this.couponParams.start_time = s - 86400
        this.couponParams.end_time = s - 1
      } else {
        this.couponParams.start_time = s
        this.couponParams.end_time = s + 86399
      }
      this.couponParams.page_no = 1
      this.getCouponData()
    },
    // 获取交易数据
    getTradeData() {
      API_Ope.getTrade(this.tradeParams).then(res => {
        console.log(res)
        this.tradeLeftDataOne[0].value = res.shop_num
        this.tradeLeftDataOne[1].value = res.pv_num
        this.tradeLeftDataOne[2].value = res.uv_num
        this.tradeLeftDataTwo[0].value = res.order_num
        this.tradeLeftDataTwo[1].value = res.re_num
        this.tradeLeftDataTwo[2].value = res.rate
        this.tradeLeftDataThree[0].value = res.order_sum
        this.tradeLeftDataThree[1].value = res.order_avg
        this.echarts.setOption({
          tooltip: {
            trigger: 'item',
            formatter: "{b} : {c}<br/>" +
              `下单转化率(下单人数/浏览人数*100%)：${res.rate1}%<br/>
            付款转化率(付款人数/下单人数*100%)：${res.rate2}%<br/>
            总转化率(付款人数/浏览人数*100%)：${res.rate3}%`
          },
          series: [
            {
              name: '漏斗图',
              type: 'funnel',
              left: '20%',
              top: 34,
              width: '80%',
              height: '80%',
              min: 0,
              max: 80,
              minSize: '0%',
              maxSize: '100%',
              sort: 'descending',
              gap: 2,
              label: {
                show: true,
                position: 'inside'
              },
              labelLine: {
                length: 10,
                lineStyle: {
                  width: 1,
                  type: 'solid'
                }
              },
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1
              },
              emphasis: {
                label: {
                  fontSize: 12
                }
              },
              data: [
                {
                  value: res.pv_num,
                  name: '浏览'
                },
                {
                  value: res.uv_num,
                  name: '下单'
                },
                {
                  value: res.order_num,
                  name: '付款'
                },
              ]
            }
          ]
        })
        this.echarts.resize()
      })
    },
    // 获取浏览数据
    getBrowseData() {
      API_Ope.getBrowseData(this.browseParams).then(res => {
        console.log(res)
        this.browseTableData = res
      })
    },
    // 获取优惠券数据
    getCouponData() {
      API_Ope.getCounpon(this.couponParams).then(res => {
        this.couponTableData = res
        console.log(res)
      })
    },
    /** 活动浏览分页大小发生改变 */
    handlePageSizeChange(size) {
      this.browseParams.page_size = size;
      this.getBrowseData();
    },

    /** 活动浏览分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.browseParams.page_no = page;
      this.getBrowseData();
    },
    /** 优惠券分页大小发生改变 */
    handlePageSizeChange1(size) {
      this.couponParams.page_size = size;
      this.getCouponData();
    },
    /** 优惠券分页页数发生改变 */
    handlePageCurrentChange1(page) {
      this.couponParams.page_no = page;
      this.getCouponData();
    }
  }
}
</script>

<style lang="scss" scoped>
.page-ops-report {
  .echartsTitle {
    display: flex;
    justify-content: space-between;
  }

  .content {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    width: 1580px;
    height: 420px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 36px;

    .left {
      .one, .two {
        display: flex;
        width: 648px;
        justify-content: space-between;

        .tradeDiv {
          width: 200px;
          height: 100px;
          // background: #34CDBD;
          background: rgba($color: (#34CDBD), $alpha: 0.08);
          opacity: 0.8;
          border-radius: 8px;
          text-align: center;

          .tradeValue {
            display: block;
            color: #222222;
            font-size: 30px;
            margin-top: 23px;
            font-family: DIN Black;
            font-weight: 500;
          }

          .tradeName {
            color: #666666;
            font-size: 15px;
            font-family: PingFang SC;
          }
        }
      }

      .two {
        margin-top: 24px;
      }

      .three {
        display: flex;
        width: 648px;
        justify-content: space-between;

        .tradeDivThree {
          width: 312px;
          height: 100px;
          // background: #FE5558;
          background: rgba($color: #FE5558, $alpha: 0.2);
          opacity: 0.8;
          border-radius: 8px;
          margin-top: 24px;
          text-align: center;

          .tradeName {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
          }

          .tradeValue {
            display: block;
            font-size: 30px;
            font-family: DIN Black;
            font-weight: 500;
            color: #FE5558;
            margin-top: 23px;
            margin-bottom: 15px;
            opacity: 1;
          }
        }
      }
    }
  }

  #funel {
    margin-left: 30px;
  }

  .tb {
    border: 1px solid #ffffff;

    /deep/ th, td {
      height: 35px;
      line-height: 35px;
      width: 160px;
      text-align: center;
    }
  }

  .tr2, .tr4 {
    background-color: #F8F8FF;
  }

  .pagination {
    float: right;
  }
}
</style>
